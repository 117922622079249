export default {
	namespace: 'revise',
	beforeEnter() {

	},
	afterEnter(barba) {
		console.log('View: revise')
		console.log(barba)

		const selectElements = barba.next.container.querySelectorAll('select');

		selectElements.forEach(select => {
			console.log('slected', select)
			// Attach change event listener to each <select>
			select.addEventListener('change', function () {
				// Update the data-rank attribute based on the selected value
				this.setAttribute('data-rank', this.value);
			});
		});
	},
	beforeLeave() {

	},
	afterLeave() {

	}
}
