export default {
	namespace: 'home',
	beforeEnter() {

	},
	afterEnter() {
		console.log('View: home')
	},
	beforeLeave() {

	},
	afterLeave() {

	}
}
