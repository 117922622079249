import { createWidget } from '@typeform/embed'
import '@typeform/embed/build/css/widget.css'

export default {
	namespace: 'survey',
	beforeEnter(barba) {
		console.log(window.tfId)
		createWidget(window.tfId, {
			container: barba.next.container.querySelector('#surveyForm'),
			hideHeaders: true,
			hideFooter: true,
			opacity: 0,
		})
	},
	afterEnter() {
		console.log('View: survey')
	},
	beforeLeave() {

	},
	afterLeave() {

	}
}
