export default {
	namespace: 'frameworkguide',
	beforeEnter() {

	},
	afterEnter() {
		console.log('View: home')
		let animated = document.querySelectorAll('.animated')
		for (let i = 0; i < animated.length; i++) {
			animated[i].classList.add('animate')
		}
	},
	beforeLeave() {

	},
	afterLeave() {

	}
}
