<template lang="pug">
	ul.nav
		li(v-for="link in nav" :class="{ 'active' : link.current }")
			a(:href="link.url") {{ link.title }}
</template>

<script>
export default {
	el: "nav",
	name: "Nav",
	data() {
		return {
			nav: window.nav
		}
	}
}
</script>
