import Vue from "vue";
// Vue Components
import Nav from './vue/nav';
// new Vue(Nav);

// Alpine.js
import Alpine from 'alpinejs'
import alpineApp from './alpine/app.js'
window.Alpine = Alpine
Alpine.data('app', alpineApp)
Alpine.start()

// Barba.js
require('./barba')

if (document.querySelectorAll('navigation').length) {
	let vmNav = new Vue(Nav)
	vmNav.$mount('navigation')
}