const aos = function (next) {

	const aosElements = next.querySelectorAll('[data-aos]')

	if (aosElements.length < 1)
		return // didn't find any, exiting...

	let options = {
		rootMargin: '0px 0px -20% 0px',
		threshold: 0
	}

	const aosObserver = new IntersectionObserver(els => {
		els.forEach(el => {
			const element = el.target

			if (el.isIntersecting) {
				element.classList.add('animate');
				aosObserver.unobserve(element);
				return;
			}

			// element.classList.remove('animate'); // Remove element on scroll up
		});
	}, options);

	aosElements.forEach(el => {
		aosObserver.observe(el);
	})

}

export default aos