import barba from '@barba/core';
import { gsap } from "gsap";
import aos from './utils/aos'
import Vue from 'vue';

barba.hooks.beforeEnter(({ next }) => {
	aos(next.container)

	// This maps elements to Vue Components:
	// if (next.container.getElementsByTagName('enews')) {
	// 	const el = next.container.getElementsByTagName('enews')
	// 	window.vmEnews = new Vue(eNewsletter)
	// 	window.vmEnews.$mount(el[0])
	// }

	// close all open UI etc:
	window.dispatchEvent(new CustomEvent('closeall'))

	// pop up login screen when being rediercted by Statmic's login handler:
	const urlParams = new URLSearchParams(window.location.search)
	if (urlParams.has('login'))
		window.dispatchEvent(new CustomEvent('togglelogin'))
})

barba.hooks.afterEnter(({ next }) => {
	// after enter hook stuff
})

barba.hooks.beforeLeave(({ next }) => {
	// bedfore leave hook stuff
})

// import all transitions in the barba transitions folder
const transitions = []
const t = require.context('./transitions/', false, /\.js$/)
t.keys().forEach(key => transitions.push(t(key).default))

// import all views in the barba views folder
const views = []
const v = require.context('./views/', false, /\.js$/)
v.keys().forEach(key => views.push(v(key).default))

// initialise Barba.js
barba.init({
	transitions,
	views,
	timeout: 5000,
	debug: true
})
