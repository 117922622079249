import { gsap } from "gsap";

const duration = 0.3 // in seconds
const footer = document.getElementById('pageFooter') // get footer

export default {
	name: 'fade',
	sync: false,

	beforeLeave(data) {
		footer.classList.add('leaving')
		return gsap.set(data.current.container, {
			css: { position: 'absolute' }
		});
	},
	leave(data) {
		return gsap.to(data.current.container, {
			opacity: 0,
			duration: duration
		});
	},
	enter(data) {
		footer.classList.remove('leaving')
		return gsap.from(data.next.container, {
			opacity: 0,
			duration: duration
		});
	}
}
